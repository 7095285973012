// Styles written by Jordan Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Gallery
\* ------------------------------------------------------------------------ */

.gallery-item {
    @for $i from 1 through 6 {
        .gallery-columns-#{$i} & {
            flex: 1 1 ((1 / $i) * 100%);
        }
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block_container {
    &.-overlay {
        left: 0;
        margin: 0 auto;
        max-width: remify($site-width);
        position: absolute;
        right: 0;
        z-index: 999;
    }
}

.header-block {
    @media screen and (max-width: $xl-break - (1 / 16)) {
        font-size: 1.25vw;
    }
}

.header_inner {
    & {
        padding: remify(($content-padding * 0.5) ($content-padding * 0.8));
    }
}

.header_logo {
    & {
        margin-right: remify(20, 16);
        padding: 0;
        width: remify(320, 16);
    }
}

.header_info-box {
    & {
        background: none;
    }
}

.header_info-box_title {
    & {
        margin-bottom: 0;
    }
}

.header_info-box_text {
    & {
        line-height: remify(5, 20);
        margin-top: 0;
    }
}

.header_info-box_countdown {
    & {
        margin-left: remify(14, 16);
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        padding: remify(($content-padding * 0.5) ($content-padding * 0.8));
    }

    > .header_inner {
        padding: 0;
    }
}

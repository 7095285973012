// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

.widget_content {
    & {
        padding-left: remify($content-padding * 1.4);
        padding-right: remify($content-padding * 1.4);
    }
}

/* alt2 variant */

.widget.-alt2 {
    & {
        padding: remify(($content-padding * 1.2) ($content-padding * 1.4));
    }

    .widget_figure {
        //margin: remify(($content-padding * -3.4) 0 0 ($content-padding * -0.4), 16);
    }

    .widget_image {
        max-width: remify(210, 16);
    }

    .widget_content {
        padding: 0;
    }
}

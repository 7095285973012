// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Helpers
\* ------------------------------------------------------------------------ */

// imports

@import "_variables";
@import "_functions";
@import "_mixins";

// generic helpers

%clearfix {
    &::after {
        clear: both !important;
        content: "" !important;
        display: table !important;
    }
}

._dark {
    color: $dark !important;
}

._light {
    color: $light !important;
}

._big {
    font-size: 1.25em !important;
}

._small {
    font-size: 0.75em !important;
}

._lowercase {
    text-transform: lowercase !important;
}

._uppercase {
    text-transform: uppercase !important;
}

._bold {
    font-weight: 700 !important;
}

._italic {
    font-style: italic !important;
}

._textcenter {
    text-align: center !important;
}

._textleft {
    text-align: left !important;
}

._textright {
    text-align: right !important;
}

._nowrap {
    white-space: nowrap !important;
}

._center {
    margin-left: auto !important;
    margin-right: auto !important;
}

._left {
    float: left !important;
}

._right {
    float: right !important;
}

._nomargin {
    margin: 0 !important;
}

._nomargintop {
    margin-top: 0 !important;
}

._nomarginright {
    margin-right: 0 !important;
}

._nomarginbottom {
    margin-bottom: 0 !important;
}

._nomarginleft {
    margin-left: 0 !important;
}

._nopadding {
    padding: 0 !important;
}

._nopaddingtop {
    padding-top: 0 !important;
}

._nopaddingright {
    padding-right: 0 !important;
}

._nopaddingbottom {
    padding-bottom: 0 !important;
}

._nopaddingleft {
    padding-left: 0 !important;
}

._notransform {
    transform: none !important;
}

._notransition {
    transition: none !important;
}

._block {
    display: block !important;
}

._flex {
    display: flex !important;
}

._visuallyhidden {
    background: darken($background, 5.4902) !important;
    border-radius: 0 !important;
    box-shadow: remify(0 0 2 2, 14) transparentize($dark, 0.4) !important;
    color: $accent !important;
    display: block !important;
    font-family: $body-font !important;
    font-size: remify(14) !important;
    font-weight: 700 !important;
    height: auto !important;
    left: -999em !important;
    letter-spacing: normal !important;
    line-height: normal !important;
    outline: none !important;
    padding: remify(15 23 14, 14) !important;
    position: absolute;
    text-decoration: none !important;
    text-shadow: none !important;
    text-transform: none !important;
    top: -999em !important;
    white-space: nowrap;
    width: auto !important;
}

._visuallyhidden:focus {
    left: remify(6, 14) !important;
    top: remify(6, 14) !important;
    z-index: 100000 !important;
}

._primary {
    color: $primary !important;
}

._primary-alt {
    color: $primary_alt !important;
}

._secondary {
    color: $secondary !important;
}

._secondary-alt {
    color: $secondary_alt !important;
}

._accent {
    color: $accent !important;
}

._accent-alt {
    color: $accent_alt !important;
}

._warning {
    color: $warning !important;
}

._warning-alt {
    color: $warning_alt !important;
}

._sticky {
    & {
        position: sticky !important;
        top: 0 !important;
    }

    // WordPress admin bar fix

    @media screen and (min-width: 601px) {
        @supports (position: -webkit-sticky) or (position: sticky) {
            .admin-bar & {
                top: 46px !important;
            }
        }
    }

    @media screen and (min-width: 783px) {
        @supports (position: -webkit-sticky) or (position: sticky) {
            .admin-bar & {
                top: 32px !important;
            }
        }
    }
}

/* visibility classes */

// when pointer is precise (mouse)
@media (pointer: fine) {
    ._touch {
        display: none !important;
    }
}

// when pointer: fine is not supported
@supports not (pointer: fine) {
    ._touch {
        display: none !important;
    }
}

// when pointer is inprecise (touch)
@media (pointer: course) {
    ._mouse {
        display: none !important;
    }
}

// when printing
@media print {
    ._noprint {
        display: none !important;
    }
}

// when screen is < 768
@media screen and (max-width: ($xs-break - 1 / 16)) {
    ._tablet:not(._phone),
    ._notebook:not(._phone),
    ._desktop:not(._phone) {
        display: none !important;
    }
}

// when screen is >= 768 and < 1024
@media screen and (min-width: $xs-break) and (max-width: ($l-break - 1 / 16)) {
    ._phone:not(._tablet),
    ._notebook:not(._tablet),
    ._desktop:not(._tablet) {
        display: none !important;
    }
}

// when screen is >= 1024 and < 1280
@media screen and (min-width: $l-break) and (max-width: ($xl-break - 1 / 16)) {
    ._phone:not(._notebook),
    ._tablet:not(._notebook),
    ._desktop:not(._notebook) {
        display: none !important;
    }
}

// when screen is >= 1280
@media screen and (min-width: $xl-break) {
    ._phone:not(._desktop),
    ._tablet:not(._desktop),
    ._notebook:not(._desktop) {
        display: none !important;
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Navigation
\* ------------------------------------------------------------------------ */

.navigation-block {
    @media screen and (max-width: $xl-break - (1 / 16)) {
        font-size: 1.25vw;
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

/* short variant */

.hero-block.-short {
    .hero_swiper-container .swiper-caption::after {
        font-range: remify($site-width * 0.6805555555555556, 16) $xl-break;
        font-size: responsive remify(0, 16) remify($content-padding * 0.6, 16);
    }
}

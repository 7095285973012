// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer_text {
    & {
        margin-bottom: 0;
    }
}

.footer_divider {
    & {
        margin-top: remify(32, 16);
    }
}

.footer_logo {
    & {
        margin: 0;
        max-width: 100%;
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Tribe Events Calendar
\* ------------------------------------------------------------------------ */

/* stylelint-disable no-descending-specificity, selector-class-pattern, selector-max-id, selector-no-qualifying-type, selector-max-type */

/* Tribe is stupidly specific with their classes... */

#tribe-events-content table.tribe-events-calendar { // Tribe is stupidly specific with their classes...
    & {
        font-size: remify(16, 16);
    }

    .tribe-events-calendar_cell {
        height: remify(150, 16);
    }

    .tribe-events-calendar_cell.mobile-active {
        background: none;
    }
}

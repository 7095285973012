/* ------------------------------------------------------------------------ *\
 * Helpers
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Colors
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Fonts                                                                    *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Widths                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Break Points                                                             *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Layers                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Functions
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Mixins
\* ------------------------------------------------------------------------ */
._dark {
  color: #000000 !important; }

._light {
  color: #FFFFFF !important; }

._big {
  font-size: 1.25em !important; }

._small {
  font-size: 0.75em !important; }

._lowercase {
  text-transform: lowercase !important; }

._uppercase {
  text-transform: uppercase !important; }

._bold {
  font-weight: 700 !important; }

._italic {
  font-style: italic !important; }

._textcenter {
  text-align: center !important; }

._textleft {
  text-align: left !important; }

._textright {
  text-align: right !important; }

._nowrap {
  white-space: nowrap !important; }

._center {
  margin-left: auto !important;
  margin-right: auto !important; }

._left {
  float: left !important; }

._right {
  float: right !important; }

._nomargin {
  margin: 0 !important; }

._nomargintop {
  margin-top: 0 !important; }

._nomarginright {
  margin-right: 0 !important; }

._nomarginbottom {
  margin-bottom: 0 !important; }

._nomarginleft {
  margin-left: 0 !important; }

._nopadding {
  padding: 0 !important; }

._nopaddingtop {
  padding-top: 0 !important; }

._nopaddingright {
  padding-right: 0 !important; }

._nopaddingbottom {
  padding-bottom: 0 !important; }

._nopaddingleft {
  padding-left: 0 !important; }

._notransform {
  transform: none !important; }

._notransition {
  transition: none !important; }

._block {
  display: block !important; }

._flex {
  -js-display: flex !important;
  display: flex !important; }

._visuallyhidden {
  background: #f1f1f1 !important;
  border-radius: 0 !important;
  box-shadow: 0em 0em 0.14286em 0.14286em rgba(0, 0, 0, 0.6) !important;
  color: #AE3D31 !important;
  display: block !important;
  font-family: "brandon-grotesque", "Helvetica", "Arial", sans-serif !important;
  font-size: 14px !important;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  height: auto !important;
  left: -999em !important;
  letter-spacing: normal !important;
  line-height: normal !important;
  outline: none !important;
  padding: 1.07143em 1.64286em 1em !important;
  position: absolute;
  text-decoration: none !important;
  text-shadow: none !important;
  text-transform: none !important;
  top: -999em !important;
  white-space: nowrap;
  width: auto !important; }

._visuallyhidden:focus {
  left: 0.42857em !important;
  top: 0.42857em !important;
  z-index: 100000 !important; }

._primary {
  color: #1D3148 !important; }

._primary-alt {
  color: #699AA9 !important; }

._secondary {
  color: #EAE0C0 !important; }

._secondary-alt {
  color: #F4EBD1 !important; }

._accent {
  color: #AE3D31 !important; }

._accent-alt {
  color: #7F2A21 !important; }

._warning {
  color: #9F0000 !important; }

._warning-alt {
  color: #F83636 !important; }

._sticky {
  position: sticky !important;
  top: 0 !important; }

@media screen and (min-width: 601px) {
  @supports (position: -webkit-sticky) or (position: sticky) {
    .admin-bar ._sticky {
      top: 46px !important; } } }

@media screen and (min-width: 783px) {
  @supports (position: -webkit-sticky) or (position: sticky) {
    .admin-bar ._sticky {
      top: 32px !important; } } }

/* visibility classes */
@media (pointer: fine) {
  ._touch {
    display: none !important; } }

@supports not (pointer: fine) {
  ._touch {
    display: none !important; } }

@media (pointer: course) {
  ._mouse {
    display: none !important; } }

@media print {
  ._noprint {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  ._tablet:not(._phone),
  ._notebook:not(._phone),
  ._desktop:not(._phone) {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  ._phone:not(._tablet),
  ._notebook:not(._tablet),
  ._desktop:not(._tablet) {
    display: none !important; } }

@media screen and (min-width: 64em) and (max-width: 79.9375em) {
  ._phone:not(._notebook),
  ._tablet:not(._notebook),
  ._desktop:not(._notebook) {
    display: none !important; } }

@media screen and (min-width: 80em) {
  ._phone:not(._desktop),
  ._tablet:not(._desktop),
  ._notebook:not(._desktop) {
    display: none !important; } }

/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Tribe Events Bar
\* ------------------------------------------------------------------------ */
/* stylelint-disable no-descending-specificity, selector-class-pattern, selector-max-id, selector-no-qualifying-type, selector-max-type */
/* Tribe is stupidly specific with their classes... */
.tribe-events-bar_views {
  width: auto; }

/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */
/* event-excerpt variant */
.article.-event-excerpt {
  background: #FFFFFF; }

.article.-event-excerpt .article_figure {
  padding: 10px;
  padding: 0.625rem; }

.article.-event-excerpt .article_header,
.article.-event-excerpt .article_content {
  padding: 30px;
  padding: 1.875rem; }

/* ------------------------------------------------------------------------ *\
 * Gallery
\* ------------------------------------------------------------------------ */
.gallery-columns-1 .gallery-item {
  flex: 1 1 100%; }

.gallery-columns-2 .gallery-item {
  flex: 1 1 50%; }

.gallery-columns-3 .gallery-item {
  flex: 1 1 33.33333%; }

.gallery-columns-4 .gallery-item {
  flex: 1 1 25%; }

.gallery-columns-5 .gallery-item {
  flex: 1 1 20%; }

.gallery-columns-6 .gallery-item {
  flex: 1 1 16.66667%; }

/* ------------------------------------------------------------------------ *\
 * Tribe Events Bar
\* ------------------------------------------------------------------------ */
/* stylelint-disable no-descending-specificity, selector-class-pattern, selector-max-id, selector-no-qualifying-type, selector-max-type */
/* Tribe is stupidly specific with their classes... */
.tribe-events-bar_input {
  margin-bottom: 0; }

.tribe-events-bar_views {
  order: 2;
  padding-bottom: 0.9375em;
  padding-top: 0.9375em; }

.tribe-events-bar_views .tribe-events-bar_text.-label {
  margin-bottom: 0.41667em; }

.tribe-events-bar_filters {
  display: block !important;
  flex: 1 1 auto;
  order: 1; }

.tribe-events-bar_filters::before, .tribe-events-bar_filters::after {
  height: 0.9375em; }

/* ------------------------------------------------------------------------ *\
 * Tribe Events Calendar
\* ------------------------------------------------------------------------ */
/* stylelint-disable no-descending-specificity, selector-class-pattern, selector-max-id, selector-no-qualifying-type, selector-max-type */
/* Tribe is stupidly specific with their classes... */
#tribe-events-content table.tribe-events-calendar {
  font-size: 1em; }

#tribe-events-content table.tribe-events-calendar .tribe-events-calendar_cell {
  height: 9.375em; }

#tribe-events-content table.tribe-events-calendar .tribe-events-calendar_cell.mobile-active {
  background: none; }

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */
/* stylelint-disable selector-class-pattern */
.swiper-caption {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0; }

.swiper-caption::after {
  content: "";
  display: block;
  font-range: 61.25em 80em;
  font-size: responsive 0em 6.875em;
  height: 1em;
  width: 100%; }

.swiper-caption .swiper-caption-inner {
  background: rgba(0, 0, 0, 0.75);
  padding: 30px 35px 50px;
  padding: 1.875rem 2.1875rem 3.125rem; }

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */
.widget_content {
  padding-left: 35px;
  padding-left: 2.1875rem;
  padding-right: 35px;
  padding-right: 2.1875rem; }

/* alt2 variant */
.widget.-alt2 {
  padding: 30px 35px;
  padding: 1.875rem 2.1875rem; }

.widget.-alt2 .widget_image {
  max-width: 13.125em; }

.widget.-alt2 .widget_content {
  padding: 0; }

/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */
.header-block_container.-overlay {
  left: 0;
  margin: 0 auto;
  max-width: 1440px;
  max-width: 90rem;
  position: absolute;
  right: 0;
  z-index: 999; }

@media screen and (max-width: 79.9375em) {
  .header-block {
    font-size: 1.25vw; } }

.header_inner {
  padding: 12.5px 20px;
  padding: 0.78125rem 1.25rem; }

.header_logo {
  margin-right: 1.25em;
  padding: 0;
  width: 20em; }

.header_info-box {
  background: none; }

.header_info-box_title {
  margin-bottom: 0; }

.header_info-box_text {
  line-height: 0.25em;
  margin-top: 0; }

.header_info-box_countdown {
  margin-left: 0.875em; }

/* fullbleed variant */
.header-block.-fullbleed {
  padding: 12.5px 20px;
  padding: 0.78125rem 1.25rem; }

.header-block.-fullbleed > .header_inner {
  padding: 0; }

/* ------------------------------------------------------------------------ *\
 * Navigation
\* ------------------------------------------------------------------------ */
@media screen and (max-width: 79.9375em) {
  .navigation-block {
    font-size: 1.25vw; } }

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */
/* short variant */
.hero-block.-short .hero_swiper-container .swiper-caption::after {
  font-range: 61.25em 80em;
  font-size: responsive 0em 0.9375em; }

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */
.content_inner::before, .content_inner::after {
  content: "";
  display: block;
  font-range: 48em 80em;
  font-size: responsive 0em 1.875em;
  height: 1em;
  width: 100%; }

.content_sidebar.-fixed {
  width: 21.25em; }

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */
.footer_text {
  margin-bottom: 0; }

.footer_divider {
  margin-top: 2em; }

.footer_logo {
  margin: 0;
  max-width: 100%; }

/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */
.header_inner {
  padding-left: 40px;
  padding-left: 2.5rem;
  padding-right: 40px;
  padding-right: 2.5rem; }

.header_search-form_container {
  padding-left: 3.75em;
  padding-right: 3.75em; }

/* fullbleed variant */
.header-block.-fullbleed {
  padding-left: 40px;
  padding-left: 2.5rem;
  padding-right: 40px;
  padding-right: 2.5rem; }

.header-block.-fullbleed > .header_inner {
  padding-left: 0;
  padding-right: 0; }

/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */
.header-block_container.-overlay {
  font-range: 90em 92.5em;
  font-size: responsive 0em 1.25em;
  margin-top: 1em; }

.header-block_container.-overlay > * {
  font-size: 16px;
  font-size: 1rem; }

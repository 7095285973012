// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

// Custom

.swiper-caption {
    & {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
    }

    &::after {
        content: "";
        display: block;
        font-range: remify($site-width * 0.6805555555555556, 16) $xl-break;
        font-size: responsive remify(0, 16) remify($content-padding * 4.4, 16);
        height: 1em;
        width: 100%;
    }

    .swiper-caption-inner {
        background: transparentize($dark, 0.25);
        padding: remify(($content-padding * 1.2) ($content-padding * 1.4) ($content-padding * 2));
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header_inner {
    & {
        padding-left: remify($content-padding * 1.6);
        padding-right: remify($content-padding * 1.6);
    }
}

.header_search-form_container {
    & {
        padding-left: remify(60, 16);
        padding-right: remify(60, 16);
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        padding-left: remify($content-padding * 1.6);
        padding-right: remify($content-padding * 1.6);
    }

    > .header_inner {
        padding-left: 0;
        padding-right: 0;
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

/* event-excerpt variant */

.article.-event-excerpt {
    & {
        background: $background;
    }

    .article_figure {
        padding: remify($content-padding * 0.4);
    }

    .article_header,
    .article_content {
        padding: remify($content-padding * 1.2);
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block_container {
    &.-overlay {
        font-range: $xxxl-break ($xxxl-break + (($content-padding * 0.8) * 2) / 16);
        font-size: responsive remify(0, 16) remify($content-padding * 0.8, 16);
        margin-top: 1em;
    }

    &.-overlay > * {
        font-size: remify(16);
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content_inner {
    &::before,
    &::after {
        content: "";
        display: block;
        font-range: $xs-break $xl-break;
        font-size: responsive remify(0, 16) remify(30, 16);
        height: 1em;
        width: 100%;
    }
}

.content_sidebar {
    &.-fixed {
        width: remify(340, 16);
    }
}

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Colors
\* ------------------------------------------------------------------------ */

$primary: #1D3148;
$primary_alt: #699AA9;

$secondary: #EAE0C0;
$secondary_alt: #F4EBD1;

$accent: #AE3D31;
$accent_alt: #7F2A21;

$light: #FFFFFF;
$light_alt: #DDDDDD;

$dark: #000000;
$dark_alt: #343D47;

$foreground: #1D3148;
$foreground_alt: #343D47;

$background: #FFFFFF;
$background_alt: #DDDDDD;

$page_background: #343D47;
$page_background_alt: #5C767C;

$warning: #9F0000;
$warning_alt: #F83636;

/* ------------------------------------------------------------------------ *\
 * Fonts                                                                    *
\* ------------------------------------------------------------------------ */

$heading-font: "Playfair Display", "Times", "Times New Roman", serif;
$body-font: "brandon-grotesque", "Helvetica", "Arial", sans-serif;

/* ------------------------------------------------------------------------ *\
 * Widths                                                                   *
\* ------------------------------------------------------------------------ */

$site-width: 1440;
$column-gap: 30;
$content-padding: 25;

/* ------------------------------------------------------------------------ *\
 * Break Points                                                             *
\* ------------------------------------------------------------------------ */

$xxxl-break: 1440em / 16;
$xxl-break: 1366em / 16;
$xl-break: 1280em / 16;
$l-break: 1024em / 16;
$m-break: 960em / 16;
$s-break: 853em / 16;
$xs-break: 768em / 16;
$xxs-break: 640em / 16;
$xxxs-break: 480em / 16;

/* ------------------------------------------------------------------------ *\
 * Layers                                                                   *
\* ------------------------------------------------------------------------ */

$layers: (
    navigation: 5,
    header: 4,
    hero: 3,
    content: 2,
    footer: 1,
);

// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Tribe Events Bar
\* ------------------------------------------------------------------------ */

/* stylelint-disable no-descending-specificity, selector-class-pattern, selector-max-id, selector-no-qualifying-type, selector-max-type */

/* Tribe is stupidly specific with their classes... */

.tribe-events-bar_views {
    & {
        width: auto;
    }
}
